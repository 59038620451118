import { createSlice } from "@reduxjs/toolkit";

const initialConfigsState = {
  entities: {
    door_width: [],
    door_height: [],
    door_model: [],
    door_color: [],
    panel_model: [],
    panel_width: [],
    panel_height: [],
    window_position: [],
    window_style: [],
    glass_type: [],
    glass_thickness: [],
    glass_color: [],
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const configsSlice = createSlice({
  name: "configs",
  initialState: initialConfigsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // configFetched
    configFetched: (state, action) => {
      state.actionsLoading = false;
      state.entities = { ...state.configs, ...action.payload };
      state.error = null;
    },
  },
});
