import { StyleSheet, Font } from "@react-pdf/renderer"
import microsoftYashei from "./msyh.ttf"

Font.register({
  family: "Microsoft Yahei",
  src: microsoftYashei,
})
// BR
const tableCol = {
  borderStyle: "solid",
  borderWidth: 1,
  textAlign: "left",
}

const tableCellBase = {
  padding: "8px 5px",
  fontFamily: "Microsoft Yahei",
  textAlign: "left",
}
const tableColBR = {
  borderStyle: "solid",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  textAlign: "left",
}

const tableColBL = {
  borderStyle: "solid",
  borderWidth: 1,
  borderRightWidth: 0,
  borderTopWidth: 0,
  textAlign: "left",
}

const tableColBBL = {
  borderStyle: "solid",
  borderWidth: 1,
  borderTopWidth: 0,
  textAlign: "left",
}

const tableColBBR = {
  borderStyle: "solid",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  textAlign: "left",
}
const tableColBB = {
  borderStyle: "solid",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopWidth: 0,
  textAlign: "left",
}

const tableColNB = {
  textAlign: "left",
}

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    textAlign: "left",
  },
  tableBottom: {
    display: "table",
    width: "auto",
    textAlign: "left",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "10px",
  },
  tableTop: {
    display: "table",
    width: "auto",
    textAlign: "left",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "10px",
  },
  tableRow: { margin: "auto", flexDirection: "row", textAlign: "left" },
  tableCol: {
    width: "25%",
    ...tableCol,
  },
  tableCol25: {
    width: "25%",
    ...tableCol,
  },
  tableCol20: {
    width: "20%",
    ...tableCol,
  },
  tableCol15: {
    width: "15%",
    ...tableCol,
  },
  tableCol40: {
    width: "40%",
    ...tableCol,
  },
  tableCol60: {
    width: "60%",
    ...tableCol,
  },
  tableCol80: {
    width: "80%",
    ...tableCol,
  },

  // BR - Border Right
  tableColBR: {
    width: "25%",
    ...tableColBR,
  },
  tableColBR20: {
    width: "20%",
    ...tableColBR,
  },
  tableColBR40: {
    width: "40%",
    ...tableColBR,
  },
  tableColBR60: {
    width: "60%",
    ...tableColBR,
  },
  tableColBR80: {
    width: "80%",
    ...tableColBR,
  },
  // NB - No BorderB
  tableColNB: {
    width: "100%",
    ...tableColNB,
  },
  tableColNB15: {
    width: "15%",
    ...tableColNB,
  },
  tableColNB20: {
    width: "20%",
    ...tableColNB,
  },
  tableColNB25: {
    width: "25%",
    ...tableColNB,
  },
  tableColNB40: {
    width: "40%",
    ...tableColNB,
  },
  tableColNB60: {
    width: "60%",
    ...tableColNB,
  },
  tableColNB80: {
    width: "80%",
    ...tableColNB,
  },
  // BB - Border Bottom
  tableColBB: {
    width: "25%",
    ...tableColBB,
  },
  tableColBB10: {
    width: "10%",
    ...tableColBB,
  },
  tableColBB15: {
    width: "15%",
    ...tableColBB,
  },
  tableColBB15Gray: {
    width: "15%",
    ...tableColBB,
    backgroundColor: "#F2F2F2",
  },

  tableColBB20: {
    width: "20%",
    ...tableColBB,
  },

  tableColBB25: {
    width: "25%",
    ...tableColBB,
  },
  tableColBB30: {
    width: "30%",
    ...tableColBB,
  },
  tableColBB40: {
    width: "40%",
    ...tableColBB,
  },
  tableColBB60: {
    width: "60%",
    ...tableColBB,
  },
  tableColBB80: {
    width: "80%",
    ...tableColBB,
  },

  // BL - Border Left
  tableColBL: {
    width: "25%",
    ...tableColBL,
  },
  tableColBL20: {
    width: "20%",
    ...tableColBL,
  },
  tableColBL40: {
    width: "40%",
    ...tableColBL,
  },
  tableColBL60: {
    width: "60%",
    ...tableColBL,
  },
  tableColBL80: {
    width: "80%",
    ...tableColBL,
  },

  // BBR - BorderBottomRight
  tableColBBR25: {
    width: "25%",
    ...tableColBBR,
  },
  tableColBBR15: {
    width: "15%",
    ...tableColBBR,
  },
  tableColBBR15Gray: {
    width: "15%",
    ...tableColBBR,
    backgroundColor: "#F2F2F2",
  },

  tableColBBR20: {
    width: "20%",
    ...tableColBBR,
  },
  tableColBBR30: {
    width: "30%",
    ...tableColBBR,
  },
  tableColBBR40: {
    width: "40%",
    ...tableColBBR,
  },
  tableColBBR45: {
    width: "45%",
    ...tableColBBR,
  },
  tableColBBR60: {
    width: "60%",
    ...tableColBBR,
  },
  tableColBBR80: {
    width: "80%",
    ...tableColBBR,
  },
  // BBL - BorderBottomLeft
  tableColBBL: {
    width: "25%",
    ...tableColBBL,
  },
  tableColBBL20: {
    width: "20%",
    ...tableColBBL,
  },
  tableColBBL40: {
    width: "40%",
    ...tableColBBL,
  },
  tableColBBL60: {
    width: "60%",
    ...tableColBBL,
  },
  tableColBBL80: {
    width: "80%",
    ...tableColBBL,
  },

  tableCell: { fontSize: 13, ...tableCellBase },
  tableCellBold: { fontSize: 13, fontWeight: 'bold', ...tableCellBase },
  tableCellGray: { fontSize: 12, ...tableCellBase },

  tableCellSmall: { fontSize: 10, ...tableCellBase },
  tableCellBig: { fontSize: 13, ...tableCellBase },
  tableCellCenter: { ...tableCellBase, textAlign: "center", fontSize: 13 },
  tableCellBigCenter: { ...tableCellBase, textAlign: "center", fontSize: 13 },
  tableCellRight: { ...tableCellBase, fontSize: 11 },
  tableCellRightColor: {
    padding: "12px 5px",
    backgroundColor: "#E3DFEB",
    textAlign: "right",
    fontSize: 16,
    fontFamily: "Microsoft Yahei",
  },
  textDetail: {
    display: "block",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "Times-New-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 14,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  separator: {
    // width: "100%",
    fontWeight: "ultralight",
    // textAlign: "center",
    position: "relative",
    marginLeft: 0,
    paddingLeft: 0,
    top: "50%",
    left: "-37px",
    transform: "translateY(-50%)",
  },
})

export default styles
