import React from "react"
import { Page, Text, View, Document, Image } from "@react-pdf/renderer"
import styles from "./styles"
import { inchToFeet, convertToYYMMDD } from "../OrdersUIHelpers"
import HousePNG from "./house.png"
const PDFContent = ({ orderForEdit, orderAttributes, intl, customer }) => {
  return (
    <Document>
      <Page style={styles.body}>
        {/* PAGE 1 TABLE 1 */}
        <View style={styles.tableTop}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB20}>
              <Image
                src={HousePNG}
                alt="house"
                style={{ width: "40px", height: "40px", marginLeft: "20px", marginBottom: "5px" }}
              />
            </View>
            <View style={styles.tableColNB60}>
              <Text style={styles.tableCellBigCenter}>
                {intl.formatMessage({id: "PDF.HEADER-1"})}
              </Text>
            </View>
            <View style={styles.tableColNB20}>
              <Text style={styles.tableCellBigCenter}></Text>
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CUSTOMER_NAME" })}
              </Text>
            </View>
            <View style={{ ...styles.tableColBB25, borderColor: "#BCBCBC" }}>
              <Text style={styles.tableCellBigCenter}>{`${
                customer ? customer.company_name : "N/A"
              }`}</Text>
            </View>
            <View style={styles.tableColNB20}>
              <Text style={styles.tableCellSmall}>
              </Text>
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "PDF.ORDER_NUMBER" })}</Text>
            </View>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellRightColor}>{orderForEdit.id || ""}</Text>
            </View>
          </View>

          {/* Row 3 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.PRODUCTION_MANAGER_SUGNATURE" })}:
              </Text>
            </View>
            <View style={styles.tableColBB15}>
              <Text style={styles.tableCellGray}></Text>
            </View>
            <View style={styles.tableColBB10}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "DETAILS.AMOUNT" })}:</Text>
            </View>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellBig}>${orderForEdit.amount || ""}</Text>
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "DETAILS.PLANNED_PRODUCTION_DATE" })}</Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCellCenter}>{convertToYYMMDD(orderForEdit.planned_production_date)}</Text>
            </View>
          </View>

          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "DETAILS.PAYMENT_STATUS" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR45}>
              <Text style={styles.tableCell}>{orderForEdit.payment_status || ""}</Text>
            </View>
            <View style={styles.tableColBBR15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "PDF.QUANTITY" })}:</Text>
            </View>
            <View style={{ ...styles.tableColBBR20, backgroundColor: "#F2F2F2" }}>
              <Text style={styles.tableCellCenter}>{orderForEdit.door_quantity || "0"} Set</Text>
            </View>
          </View>
          {/* Row 5 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CONFIGURATION_REQUIREMENTS_FOR_THIS_PRODUCT" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR80}>
              <Text style={styles.tableCell}>
                * {intl.formatMessage({id: "DETAILS.DOOR_SIZE"})}:<Text style={styles.tableCellBold}>{orderAttributes.door_width || ""}'X
                {inchToFeet(orderAttributes.door_height) || ""}, </Text>
                {intl.formatMessage({id: "DETAILS.DOOR_WIDTH"})}:{" "}
                {orderAttributes.door_width || ""}', {intl.formatMessage({id: "DETAILS.DOOR_HEIGHT"})}:{" "}
                {inchToFeet(orderAttributes.door_height) || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_MODEL"})}: {orderAttributes.door_model || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_COLOR"})}: {orderAttributes.door_color || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.WINDOW_POSITION"})}: {orderAttributes.window_position || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_STYLE"})}:{" "}
                {orderAttributes.window_style || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.GLASS_TYPE"})}: {orderAttributes.glass_type + " - " + orderAttributes.glass_thickness + " - " + orderAttributes.glass_color || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_QUANTITY"})}:{" "}
                {orderForEdit.window_quantity || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_ACCESSORIES"})}: {orderForEdit.other_accessories || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_REQUIREMENTS"})}: {orderForEdit.other_requirements || ""} 
                {"\n"}
                {"\n"}
              </Text>
            </View>
          </View>
          {/* Row 6 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.ESTIMATED_PICKUP_TIME" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}>{convertToYYMMDD(orderForEdit.ready_at) || ""}</Text>
            </View>
            <View style={styles.tableColBB30}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CUSTOMER_SIGNATURE" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR30}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
        </View>

        {/* SEPARATOR START */}
        <View style={styles.separator}>
          <Text>
            ------------------------------------------------------------------------------------------------------
          </Text>
        </View>
        {/* SEPARATOR END */}

        {/* PAGE 1 TABLE 2 */}
        <View style={styles.tableBottom}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={{ textAlign: "center" }}>
              <Image src={HousePNG} alt="house" style={{ width: "40px", height: "40px" }} />
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB}>
              <Text style={styles.tableCellBigCenter}>
                {intl.formatMessage({id: "PDF.HEADER-2"})}
              </Text>
            </View>
          </View>

          {/* Row 3 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CUSTOMER_NAME" })}
              </Text>
            </View>
            <View style={{ ...styles.tableColBB25, borderColor: "#D8D8D8" }}>
              <Text style={styles.tableCellBigCenter}>{`${
                customer ? customer.company_name : "N/A"
              }`}</Text>
            </View>
            <View style={styles.tableColNB20}>
              <Text style={styles.tableCellSmall}>
              </Text>
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "PDF.ORDER_NUMBER" })}</Text>
            </View>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellRightColor}>{orderForEdit.id || ""}</Text>
            </View>
          </View>

          {/* Row 4 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.MARKETING_MANAGER_SUGNATURE" })}:
              </Text>
            </View>
            <View style={styles.tableColBB15}>
              <Text style={styles.tableCellGray}></Text>
            </View>
            <View style={styles.tableColBB10}>
              {/* <Text style={styles.tableCell}>{intl.formatMessage({ id: "DETAILS.AMOUNT" })}:</Text> */}
            </View>
            <View style={styles.tableColBB20}>
              {/* <Text style={styles.tableCellBig}>${orderForEdit.amount || ""}</Text> */}
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "DETAILS.PLANNED_PRODUCTION_DATE" })}</Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCellCenter}>{convertToYYMMDD(orderForEdit.planned_production_date)}</Text>
            </View>
          </View>

          {/* Row 5 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "DETAILS.PAYMENT_STATUS" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR45}>
              <Text style={styles.tableCell}>{orderForEdit.payment_status || ""}</Text>
            </View>
            <View style={styles.tableColBBR15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "PDF.QUANTITY" })}:</Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCellCenter}>{orderForEdit.door_quantity || "0"} Set</Text>
            </View>
          </View>
          {/* Row 6 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CONFIGURATION_REQUIREMENTS_FOR_THIS_PRODUCT" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR80}>
              <Text style={styles.tableCell}>
                * {intl.formatMessage({id: "DETAILS.DOOR_SIZE"})}: {orderAttributes.door_width || ""}'X
                {inchToFeet(orderAttributes.door_height) || ""}, {intl.formatMessage({id: "DETAILS.DOOR_WIDTH"})}:{" "}
                {orderAttributes.door_width || ""}', {intl.formatMessage({id: "DETAILS.DOOR_HEIGHT"})}:{" "}
                {inchToFeet(orderAttributes.door_height) || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_MODEL"})}: {orderAttributes.door_model || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_COLOR"})}: {orderAttributes.door_color || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.WINDOW_POSITION"})}: {orderAttributes.window_position || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_STYLE"})}:{" "}
                {orderAttributes.window_style || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.GLASS_TYPE"})}: {orderAttributes.glass_type + " - " + orderAttributes.glass_thickness + " - " + orderAttributes.glass_color || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_QUANTITY"})}:{" "}
                {orderForEdit.window_quantity || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_ACCESSORIES"})}: {orderForEdit.other_accessories || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_REQUIREMENTS"})}: {orderForEdit.other_requirements || ""} {"\n"}
                {"\n"}
              </Text>
            </View>
          </View>
          {/* Row 7 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.ESTIMATED_PICKUP_TIME" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}>{convertToYYMMDD(orderForEdit.ready_at) || ""}</Text>
            </View>
            <View style={styles.tableColBB40}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CUSTOMER_SIGNATURE" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
        </View>
      </Page>

      {/* PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 */}
      {/* PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 */}
      {/* PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 */}
      {/* PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 */}
      {/* PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 PAGE2 */}
      <Page style={styles.body}>
        {/* PAGE 2 TABLE 1 */}
        <View style={styles.tableTop}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB80}>
              <Text style={styles.tableCellCenter}>
                {intl.formatMessage({id: "PDF.HEADER-3"})}
              </Text>
            </View>

            <View style={styles.tableColNB20}>
              <Image
                src={HousePNG}
                alt="house"
                style={{ width: "40px", height: "40px", marginLeft: "20px", marginBottom: "5px" }}
              />
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CUSTOMER_NAME" })}
              </Text>
            </View>
            <View style={{ ...styles.tableColBB30, borderColor: "#D8D8D8" }}>
              <Text style={styles.tableCellBigCenter}>{`${
                customer ? customer.company_name : "N/A"
              }`}</Text>
            </View>
            <View style={styles.tableColNB15}>
              <Text style={styles.tableCellSmall}>
              </Text>
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "PDF.ORDER_NUMBER" })}</Text>
            </View>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellRightColor}>{orderForEdit.id || ""}</Text>
            </View>
          </View>

          {/* Row 4 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.PRODUCTION_MANAGER_SUGNATURE" })}:
              </Text>
            </View>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellGray}></Text>
            </View>
            <View style={styles.tableColBB10}>
              {/* <Text style={styles.tableCell}>{intl.formatMessage({ id: "DETAILS.AMOUNT" })}:</Text> */}
            </View>
            <View style={styles.tableColBB15}>
              {/* <Text style={styles.tableCellBig}>${orderForEdit.amount || ""}</Text> */}
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "DETAILS.PLANNED_PRODUCTION_DATE" })}</Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCellCenter}>{convertToYYMMDD(orderForEdit.planned_production_date)}</Text>
            </View>
          </View>

          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "DETAILS.PAYMENT_STATUS" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR40}>
              <Text style={styles.tableCell}>{orderForEdit.payment_status || ""}</Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "DETAILS.ORDER_AMOUNT" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCellCenter}>{orderForEdit.door_quantity || "0"} Set</Text>
            </View>
          </View>
          {/* Row 5 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CONFIGURATION_REQUIREMENTS_FOR_THIS_PRODUCT" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR80}>
              <Text style={styles.tableCell}>
                * {intl.formatMessage({id: "DETAILS.DOOR_SIZE"})}: {orderAttributes.door_width || ""}'X
                {inchToFeet(orderAttributes.door_height) || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_MODEL"})}: {orderAttributes.door_model || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_COLOR"})}: {orderAttributes.door_color || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.WINDOW_POSITION"})}: {orderAttributes.window_position || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_STYLE"})}:{" "}
                {orderAttributes.window_style || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.GLASS_TYPE"})}: {orderAttributes.glass_type + " - " + orderAttributes.glass_thickness + " - " + orderAttributes.glass_color || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_QUANTITY"})}:{" "}
                {orderForEdit.window_quantity || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_ACCESSORIES"})}: {orderForEdit.other_accessories || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_REQUIREMENTS"})}: {orderForEdit.other_requirements || ""} {"\n"}
                {"\n"}
              </Text>
            </View>
          </View>
          {/* Row 6 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.ESTIMATED_PICKUP_TIME" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}>{convertToYYMMDD(orderForEdit.ready_at) || ""}</Text>
            </View>
            <View style={styles.tableColBB40}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.MARKETING_MANAGER_SUGNATURE" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
        </View>

        {/* SEPARATOR START */}
        <View style={styles.separator}>
          <Text>
            ------------------------------------------------------------------------------------------------------
          </Text>
        </View>
        {/* SEPARATOR END */}

        {/* PAGE 2 TABLE 2 */}
        <View style={styles.tableBottom}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB}>
              <Text style={{ ...styles.tableCellBigCenter, marginBottom: "5px" }}>
                {intl.formatMessage({id: "PDF.HEADER-4"})}
              </Text>
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNB20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CUSTOMER_NAME" })}
              </Text>
            </View>
            <View style={{ ...styles.tableColBB30, borderColor: "#D8D8D8" }}>
              <Text style={styles.tableCellBigCenter}>{`${
                customer ? customer.company_name : "N/A"
              }`}</Text>
            </View>
            <View style={styles.tableColNB15}>
              <Text style={styles.tableCellSmall}>
              </Text>
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "PDF.ORDER_NUMBER" })}</Text>
            </View>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellRightColor}>{orderForEdit.id || ""}</Text>
            </View>
          </View>

          {/* Row 3 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.PRODUCER_SIGNATURE" })}:
              </Text>
            </View>
            <View style={styles.tableColBB20}>
              <Text style={styles.tableCellGray}></Text>
            </View>
            <View style={styles.tableColBB10}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.COMPLETION_TIME" })}:
              </Text>
            </View>
            <View style={{ ...styles.tableColBB15, backgroundColor: "#D9D9D9" }}>
              <Text style={styles.tableCellBig}>
                {convertToYYMMDD(orderForEdit.production_completed_at) || ""}
              </Text>
            </View>
            <View style={styles.tableCol15}>
              <Text style={styles.tableCell}>{intl.formatMessage({ id: "DETAILS.PLANNED_PRODUCTION_DATE" })}</Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCellCenter}>{convertToYYMMDD(orderForEdit.planned_production_date)}</Text>
            </View>
          </View>

          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "DETAILS.PAYMENT_STATUS" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR40}>
              <Text style={styles.tableCell}>{orderForEdit.payment_status || ""}</Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "DETAILS.ORDER_AMOUNT" })}:
              </Text>
            </View>
            <View style={{ ...styles.tableColBBR20, backgroundColor: "#D9D9D9" }}>
              <Text style={styles.tableCellCenter}>{orderForEdit.door_quantity || "0"} Set</Text>
            </View>
          </View>
          {/* Row 5 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCell}>
                {intl.formatMessage({ id: "PDF.CONFIGURATION_REQUIREMENTS_FOR_THIS_PRODUCT" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR80}>
              <Text style={styles.tableCell}>
                * {intl.formatMessage({id: "DETAILS.DOOR_SIZE"})}: {orderAttributes.door_width || ""}'X
                {inchToFeet(orderAttributes.door_height) || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_MODEL"})}: {orderAttributes.door_model || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.DOOR_COLOR"})}: {orderAttributes.door_color || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.WINDOW_POSITION"})}: {orderAttributes.window_position || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_STYLE"})}:{" "}
                {orderAttributes.window_style || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.GLASS_TYPE"})}: {orderAttributes.glass_type + " - " + orderAttributes.glass_thickness + " - " + orderAttributes.glass_color || ""}; {intl.formatMessage({id: "DETAILS.WINDOW_QUANTITY"})}:{" "}
                {orderForEdit.window_quantity || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_ACCESSORIES"})}: {orderForEdit.other_accessories || ""}
                {"\n"}* {intl.formatMessage({id: "DETAILS.OTHER_REQUIREMENTS"})}: {orderForEdit.other_requirements || ""} {"\n"}
                {"\n"}
              </Text>
            </View>
          </View>
          {/* Row 6 */}
          <View style={styles.tableRow}>
            <View style={styles.tableColBBL20}>
              <Text style={styles.tableCellSmall}>
                {intl.formatMessage({ id: "PDF.ESTIMATED_PICKUP_TIME" })}:
              </Text>
            </View>
            <View style={styles.tableColBBR20}>
              <Text style={styles.tableCell}>{convertToYYMMDD(orderForEdit.ready_at) || ""}</Text>
            </View>
            <View style={styles.tableColBB30}>
              <Text style={styles.tableCell}>{intl.formatMessage({id: "PDF.LOCATION_OF_STORAGE"})}:</Text>
            </View>
            <View style={styles.tableColBBR30}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PDFContent
