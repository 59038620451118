import { createSlice } from "@reduxjs/toolkit"

const initialProductionSummaryState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  productionDetails: null,
  companyNames: [],
}
export const callTypes = {
  list: "list",
  action: "action",
}

export const productionSummarySlice = createSlice({
  name: "salesSummary",
  initialState: initialProductionSummaryState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // findSalesSummary
    productionSummaryFetched: (state, action) => {
      const { entities, totalCount } = action.payload.data
      if (!state.entities) {
        const companyNames = []
        entities.forEach((d) => {
          companyNames.push({ id: d.id, company_name: d.company_name })
        })
        state.companyNames = companyNames
      }
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },

    // findSalesSummary
    productionSummaryDetailsFetched: (state, action) => {
      state.listLoading = false
      state.actionsLoading = false
      state.error = null
      state.productionDetails = action.payload.data
    },
  },
})
