import React from "react"
import { useIntl } from "react-intl"

export const OrderStatusCssClasses = ["danger", "success", ""]
export const OrderStatusTitles = ["retired", "active"]

export const WorkStatusTitles = [
  { name: "Pending", value: "pending", default: true },
  { name: "Pending Lack Parts", value: "pending_lack_parts" },
  { name: "Working", value: "working" },
  { name: "Complete", value: "ready_for_pick_up" },
  // { name: "Picked Up", value: "picked_up" },
]

export const PaymentStatusTitles = [
  { name: "Not Paid", value: "not_paid", default: true },
  { name: "Paid", value: "paid" },
  { name: "Refunded", value: "refunded" },
  { name: "Paid But Not Picked Up", value: "paid_not_picked_up" },
  { name: "Not Paid Picked Up", value: "not_paid_picked_up" },
]

export const PaymentTypeTitles = [
  { name: "Cash", value: "cash", default: true },
  { name: "Check", value: "check" },
]

export const OrderConditionCssClasses = ["success", "danger", ""]
export const defaultSorted = [{ dataField: "id", order: "asc" }]
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
]

export const initialFilter = {
  filter: {
    // id: 0
  },
  sortOrder: "desc", // asc||desc
  sortField: "PO Number",
  pageNumber: 1,
  pageSize: 50,
}

export const inchToFeet = (inch) =>
  `${Math.floor(inch / 12)}${inch / 12 > 0 ? "'" : ""} ${inch % 12 > 0 ? inch % 12 : ""}${
    inch % 12 > 0 ? '"' : ""
  }`.trim()

export const convertToMonthDate = (date_string) => {
  var days = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
  let date = new Date(date_string)

  const month = days[date.getMonth()]
  const day = date.getDate()

  let result = date_string ? `${month} ${day}` : "N\\A"

  return result
}

export function checkTime(i) {
  if (i < 10) {
    i = "0" + i
  }
  return i
}

export const convertToYYMMDD = (date_string) => {
  let date = new Date(date_string)

  const y = date.getFullYear()
  const month = checkTime(date.getMonth() + 1)
  const day = checkTime(date.getDate())

  let result = date_string ? `${y}/${month}/${day}` : "N\\A"

  return result
}
