import { createSlice } from "@reduxjs/toolkit";

const initialOrderDetailsState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  entity: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const orderDetailsSlice = createSlice({
  name: "orderDetails",
  initialState: initialOrderDetailsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // orderDetailsFetched
    orderDetailsFetched: (state, action) => {
      const { entity } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entity = entity;
    },
  },
});
