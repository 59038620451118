/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useIntl } from "react-intl"

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl()
  const location = useLocation()

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : ""
  }

  const { user_group } = useSelector((state) => state.auth.user, shallowEqual)

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {(user_group === "admin" || user_group === "employee") && (
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/e-commerce/products", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/e-commerce/products">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
                </span>
                {/* <span className="menu-text">Products</span> */}
                <span className="menu-text">
                  {intl.formatMessage({ id: "MENU.LIST.PRODUCTS" })}
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            <li
              className={`menu-item ${getMenuItemActive("/e-commerce/orders/new", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/e-commerce/orders/new">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                </span>
                {/* <span className="menu-text">New Order</span> */}
                <span className="menu-text">
                  {intl.formatMessage({ id: "MENU.LIST.NEW_ORDER" })}
                </span>
              </NavLink>
            </li>

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/e-commerce/orders-detailed", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/e-commerce/orders-detailed">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} />
                </span>
                {/* <span className="menu-text">Orders</span> */}
                <span className="menu-text">{intl.formatMessage({ id: "MENU.LIST.ORDERS" })}</span>
              </NavLink>
            </li>

            {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/e-commerce/orders",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/e-commerce/orders"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  />
                </span>
                <span className="menu-text">Orders</span>
              </NavLink>

              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Orders</span>
                    </span>
                  </li>
                  // begin::2 Level */}
            {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/e-commerce/orders"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/e-commerce/orders">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">All Orders</span>
                    </NavLink>
                  </li>{" "} */}
            {/*end::2 Level*/}
            {/*begin::2 Level*/}
            {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/e-commerce/orders-detailed"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/e-commerce/orders-detailed"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Orders Detailed</span>
                    </NavLink>
                  </li>{" "}
                  {/*end::2 Level*/}
            {/* </ul>
              </div>
            </li> */}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/e-commerce/customers", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/e-commerce/customers">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Adress-book2.svg")} />
                </span>
                {/* <span className="menu-text">Customers</span> */}
                <span className="menu-text">
                  {intl.formatMessage({ id: "MENU.LIST.CUSTOMERS" })}
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        )}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/e-commerce/production", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/e-commerce/production">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            {/* <span className="menu-text">Production</span> */}
            <span className="menu-text">{intl.formatMessage({ id: "MENU.LIST.PRODUCTION" })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {user_group === "admin" && (
          <li
            className={`menu-item ${getMenuItemActive("/e-commerce/users", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/e-commerce/users">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              {/* <span className="menu-text">Users</span> */}
              <span className="menu-text">{intl.formatMessage({ id: "MENU.LIST.USERS" })}</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {(user_group === "admin" || user_group === "employee") && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/e-commerce/reports",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/e-commerce/reports">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
              </span>
              {/* <span className="menu-text">Reports</span> */}
              <span className="menu-text">{intl.formatMessage({ id: "MENU.LIST.REPORTS" })}</span>
            </NavLink>

            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    {/* <span className="menu-text">Reports</span> */}
                    <span className="menu-text">
                      {intl.formatMessage({ id: "MENU.LIST.REPORTS" })}
                    </span>
                  </span>
                </li>
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/e-commerce/reports/sales-summary")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/e-commerce/reports/sales-summary">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    {/* <span className="menu-text">Sales Summary</span> */}
                    <span className="menu-text">
                      {intl.formatMessage({ id: "MENU.LIST.SALES_SUMMARY" })}
                    </span>
                  </NavLink>
                </li>{" "}
                {/* PRODUCTION SUMMARY */}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/e-commerce/reports/production-summary"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/e-commerce/reports/production-summary">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    {/* <span className="menu-text">Production Summary</span> */}
                    <span className="menu-text">
                      {intl.formatMessage({ id: "MENU.LIST.PRODUCTION_SUMMARY" })}
                    </span>
                  </NavLink>
                </li>{" "}
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
