import axios from "axios";

export const AUTH_URL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_PUBLIC_URL}/api/auth`:"http://localhost:8080/api/auth";
//export const LOGIN_URL = "api/auth/login";
export const LOGIN_URL = `${AUTH_URL}/login`;
export const REGISTER_URL = `${AUTH_URL}/register`;
export const REQUEST_PASSWORD_URL = `${AUTH_URL}/forgot-password`;

//export const ME_URL = "api/users/me";
export const ME_URL = `${AUTH_URL}/me`;

export function login(username, password) {
  const result = axios.post(LOGIN_URL, { username, password },);
  return result;
}

export function register(email, firstName, lastName, username, password) {
  return axios.post(REGISTER_URL, { email, firstName, lastName, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
