import axios from "axios"
import { func } from "prop-types"

//export const ORDERS_URL = "api/orders";
export const ORDERS_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_PUBLIC_URL}/api/orders`
    : "http://localhost:8080/api/orders"

export const ORDER_COMMENTS_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_PUBLIC_URL}/api/order_comments`
    : "http://localhost:8080/api/order_comments"
// CREATE =>  POST: add a new order to the server
export function createOrder(order) {
  return axios.post(`${ORDERS_URL}`, order)
}

// READ
export function getAllOrders() {
  return axios.get(ORDERS_URL)
}

export function getOrderById(orderId) {
  return axios.get(`${ORDERS_URL}/${orderId}`)
}
export function getOrderAttributes(orderId) {
  return axios.get(`${ORDERS_URL}/attributes/${orderId}`)
}

export function getOrderCustomers(orderId) {
  return axios.get(`${ORDERS_URL}/customers`)
}
export function getProductReportCustomers() {
  return axios.get(`${ORDERS_URL}/product-report-customers`)
}
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrders(queryParams) {
  const result = axios.post(`${ORDERS_URL}/find`, { queryParams })
  return result
}

export function findAllOrders(queryParams) {
  const result = axios.post(`${ORDERS_URL}/findAll`, { queryParams })
  return result
}
export function findAllOrdersDueTomorrow(queryParams) {
  const result = axios.post(`${ORDERS_URL}/findAllDueTomorrow`, {
    queryParams,
  })
  return result
}
export function findAllOrdersDueDayAfter(queryParams) {
  const result = axios.post(`${ORDERS_URL}/findAllDueDayAfter`, {
    queryParams,
  })
  return result
}
export function findAllOrdersOverDue(queryParams) {
  const result = axios.post(`${ORDERS_URL}/findAllOverDue`, {
    queryParams,
  })
  return result
}

// UPDATE => PUT: update the procuct on the server
export function updateOrder(order) {
  return axios.patch(`${ORDERS_URL}/${order.id}`, order)
}

export function updateOrderStatus(order) {
  return axios.patch(`${ORDERS_URL}/status/${order.id}`, order)
}

export function updateOrderComment({ id, orderComment }) {
  return axios.patch(`${ORDERS_URL}/comment/${id}`, { orderComment })
}

export function fetchOrderComment(orderId) {
  return axios.get(`${ORDERS_URL}/comment/${orderId}`)
}

export function fetchOrderComments(orderId) {
  return axios.get(`${ORDER_COMMENTS_URL}/order/${orderId}`)
}
// UPDATE Status
export function updateStatusForOrders(ids, status) {
  return axios.post(`${ORDERS_URL}/updateStatusForOrders`, {
    ids,
    status,
  })
}

// DELETE => delete the order from the server
export function deleteOrder(orderId) {
  return axios.delete(`${ORDERS_URL}/${orderId}`)
}

// DELETE Orders by ids
export function deleteOrders(ids) {
  return axios.post(`${ORDERS_URL}/deleteOrders`, { ids })
}

export function getOrderItemProducts() {
  return axios.get(`${ORDERS_URL}/order-item-products`)
}

export function getOrderItemAttributes(params) {
  const { pid } = params
  return axios.get(`${ORDERS_URL}/order-item-attributes?pid=${pid}`)
}

export function getOrderItemAttributeValues(params) {
  const { pid, attr_name } = params
  return axios.get(`${ORDERS_URL}/order-item-attribute-values?pid=${pid}&attr_name=${attr_name}`)
}

export function getOrderProductsAttributes() {
  return axios.get(`${ORDERS_URL}/order-products-attributes`)
}
