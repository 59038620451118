import React, { useEffect } from "react"
import { PDFViewer } from "@react-pdf/renderer"
import * as ordersActions from "../../_redux/orders/ordersActions"

import { shallowEqual, useDispatch, useSelector } from "react-redux"

import PDFContent from "./pdf/PDFContent"
import { useIntl } from "react-intl"

export const OrderDetailsPDFRender = ({ match }) => {
  const intl = useIntl()
  const id = match && match.params.id
  const dispatch = useDispatch()
  const { orderForEdit, attributes } = useSelector((state) => state.orders, shallowEqual)

  useEffect(() => {
    if (id) {
      dispatch(ordersActions.fetchOrder(id))
      dispatch(ordersActions.fetchOrderAttributes(id))
    }
  }, [id, dispatch])

  if (orderForEdit && attributes) {
    return (
      // <div>{id}</div>
      <PDFViewer>
        <PDFContent
          intl={intl}
          orderForEdit={orderForEdit ? orderForEdit : {}}
          orderAttributes={attributes ? attributes : {}}
          customer={orderForEdit ? orderForEdit.customers : {}}
        />
      </PDFViewer>
      // <PDFViewer>
      // <PDFContent />
      // </PDFViewer>
    )
  } else {
    return <div>Loading..</div>
  }
}
